import { useState } from 'react';
import '../assets/css/custom.css';
import Footer from './HeaderFooter/footer';
import Header from './HeaderFooter/header';
import ContactUs1 from '../assets/img/pg-contact-us-1.png'
import ContactUsiPad from '../assets/img/pg-contact-us-1-iPad.png'
import ContactUsMobile from '../assets/img/pg-contact-us-1-Mobile.png'
// import CallLogo from '../assets/img/pg-contact-us-phone.png'
import EmailLogo from '../assets/img/pg-contact-us-email.png'
import Sec2OfficeImage from '../assets/img/pg-contact-us-image-sec-2.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import CustomButton from './Button/CustomButton';

const ContactUs = () => {

    const [fullName, setFullName] = useState("");
    const [email, setEmail] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [message, setMessage] = useState("");


    const [validfullName, setValidfullName] = useState(true);
    const [validEmail, setValidEmail] = useState(true);
    const [validPhoneNumber, setValidPhoneNumber] = useState(true);
    const [validCompanyName, setValidCompanyName] = useState(true);
    const [validMessage, setValidMessage] = useState(true);

    const [emailSent, setEmailSent] = useState(false);


    const onFullName = (e) => {
        setFullName(e.target.value);
        if (e.target.value === "") {
            setValidfullName(false);
        } else {
            setValidfullName(true);
        }
    }

    const onEmail = (e) => {
        setEmail(e.target.value);
        setValidEmail(validateEmail(e.target.value))
    }

    const onChangePhoneNumber = (e) => {
        let phoneNumber = parseInt(e.target.value.replace(/[^0-9.]/g, ""));
        setPhoneNumber(formatPhoneNumber(phoneNumber ? phoneNumber : ''));
        if (e.target.value === '') {
            setValidPhoneNumber(false);
        } else {
            setValidPhoneNumber(true);
        }
    }

    const onCompanyName = (e) => {
        setCompanyName(e.target.value);
        if (e.target.value === '') {
            setValidCompanyName(false);
        } else {
            setValidCompanyName(true);
        }
    }

    const onMessage = (e) => {
        setMessage(e.target.value);
        if (e.target.value === '') {
            setValidMessage(false);
        } else {
            setValidMessage(true);
        }
    }

    const [submitDisabled, setSubmitDisabled] = useState(false);

    async function onContactUsSubmit(e) {
        setSubmitDisabled(true);

        fullName === '' && setValidfullName(false)
        email === '' && setValidEmail(false)
        phoneNumber === '' && setValidPhoneNumber(false)
        companyName === '' && setValidCompanyName(false)
        message === '' && setValidMessage(false)


        if (fullName && email && phoneNumber && companyName && message && validEmail) {
            const options = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    email: email,
                    name: fullName,
                    phoneNumber: phoneNumber,
                    companyName: companyName,
                    message: message
                })
            };

            await fetch('https://localhost:44338/api/EmailEnquiry', options)
                .then(response => response.json())
                .then(response => console.log(response.statusCode === 200 ? setEmailSent(true) : setEmailSent(false)))
                .catch(err => console.error(err));
        }
        setSubmitDisabled(false);
    }


    const formatPhoneNumber = (phoneNumberString) => {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/);
        if (match) {
            return '' + match[1] + ' ' + match[2] + ' ' + match[3];
        }
        return phoneNumberString.toString();
    }

    const validateEmail = (mail) => {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
            return true
        }
        return false
    }


    return (
        <>
            <Header />

            <div className='content-wrap banner-adjust-navbar'>

                {/* WEB */}
                <div className='contact-section-1-container d-none d-lg-block'>
                    <img src={ContactUs1} alt='...' className='w-100 contact-section-1-image h-50' />
                    <div className='container'>
                        <div className='contact-section-1-text-block'>
                            <div className="animate__animated animate__fadeInLeft animate__delay-1s">
                                <p>Contact Us</p>
                                {/* <h4>Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit.</h4> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* iPad */}
                <div className='contact-section-1-container d-none d-md-block d-lg-none'>
                    <img src={ContactUsiPad} alt='...' className='w-100 contact-section-1-image-ipad' />
                    <div className='container'>
                        <div className='contact-section-1-text-block'>
                            <div className="animate__animated animate__fadeInLeft animate__delay-1s">
                                <p>Contact Us</p>
                                {/* <h4>Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit.</h4> */}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile */}
                <div className='contact-section-1-container d-block d-sm-none'>
                    <img src={ContactUsMobile} alt='...' className='w-100 contact-section-1-image' />
                    <div className='contact-section-1-text-block-mob p-4'>
                        <div className="animate__animated animate__fadeInLeft animate__delay-1s">
                            <p>Contact Us</p>
                            {/* <h4>Lorem Ipsum Dolor Sit Amet, Consectetur Adipiscing Elit.</h4> */}
                        </div>
                    </div>
                </div>

                <div className='contact-section-2-container p-sm-0  p-md-5'>
                    <div className='container p-0'>
                        <div className='row'>
                            <div className='col-sm-12 col-md-6 col-lg-6 p-0'>
                                <div className="card contact-section-2-wrapper">
                                    <div className="card-body contact-section-2-address">
                                        <h5 className="card-title">India</h5>
                                        <h6 className="card-subtitle mb-3">
                                            3rd Floor, No.6, Tulsi Green, Laxman Nagar, Balewadi, Pune-411045
                                        </h6>
                                        <div className="contact-info">
                                            <div className="contact-detail">
                                                <img src={EmailLogo} alt='...' />
                                                <a className='contact-detail-text' href="mailto:info@twyt.in">info@twyt.in</a>
                                            </div>
                                            <br />
                                            {/* <div className="contact-detail">
                                                <img src={CallLogo} alt='...' />
                                                <a className='contact-detail-text' href="tel:+91993123456">+91 993 123456</a>
                                            </div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-6 p-0'>
                                <iframe
                                    className='h-100 w-100'
                                    title="Google Maps"
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3782.1061415747527!2d73.770170093858!3d18.56925288909655!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b94abebfc8ed%3A0xd6b039ca59b84d6d!2sTulsi%20Green%2C%20Laxman%20Nagar%2C%20Baner%2C%20Pune%2C%20Maharashtra%20411045!5e0!3m2!1sen!2sin!4v1703248105988!5m2!1sen!2sin"
                                    width="600"
                                    height="450"
                                    style={{ border: 0 }}
                                    allowFullScreen=""
                                    loading="lazy"
                                    referrerPolicy="no-referrer-when-downgrade"
                                ></iframe>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='contact-section-2-container p-sm-0  p-md-5 pt-md-0'>
                    <div className='container p-0'>
                        <div className='row'>
                            {/* <div className='col-sm-12 col-md-12 col-lg-6 p-0 order-sm-1 order-2'> */}
                            <div className='col-sm-12 col-md-12 col-lg-6 p-0 order-sm-2 order-1'>
                                <div className="contact-section-2-wrapper contact-section-3-container px-4">
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <AnimationOnScroll animateIn="animate__zoomIn">
                                                <h1>Connect With Us</h1>
                                            </AnimationOnScroll>
                                        </div>
                                    </div>
                                    {emailSent ?
                                        <>
                                            <div className='d-flex flex-column justify-content-center align-items-center h-100 '>
                                                <div className="thank-you-heading">
                                                    Thank You, {fullName}
                                                </div>
                                                <div className="thank-you-sub-heading">
                                                    Our team will be in touch with you soon.
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">Name</label>
                                                    <input type="text" className={`form-control ${!validfullName && 'is-invalid'}`} id="exampleFormControlInput1" placeholder="" value={fullName} onChange={e => onFullName(e)} />
                                                </div>

                                            </div>
                                            <div className='col-md-6'>
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">Email</label>
                                                    <input type="email" className={`form-control ${!validEmail && 'is-invalid'}`} id="exampleFormControlInput1" placeholder="" value={email} onChange={e => onEmail(e)} />
                                                </div>

                                            </div>
                                            <div className='col-md-6'>
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">Phone</label>
                                                    <input type="text" className={`form-control ${!validPhoneNumber && 'is-invalid'}`} id="exampleFormControlInput1" placeholder="" value={phoneNumber} onChange={e => onChangePhoneNumber(e)} />
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="mb-3">
                                                    <label for="exampleFormControlInput1" className="form-label">Company</label>
                                                    <input type="text" className={`form-control ${!validCompanyName && 'is-invalid'}`} id="exampleFormControlInput1" placeholder="" value={companyName} onChange={e => onCompanyName(e)} />
                                                </div>
                                            </div>
                                            <div className='col-12'>
                                                <div className="mb-3">
                                                    <label for="exampleFormControlTextarea1" className="form-label">Message</label>
                                                    <textarea className={`form-control ${!validMessage && 'is-invalid'}`} id="exampleFormControlTextarea1" rows="3" value={message} onChange={e => onMessage(e)} ></textarea>
                                                </div>
                                            </div>
                                            <div className='col text-center'>
                                               <CustomButton text="Submit" faIcon="fa-solid fa-paper-plane" onCustomClick={onContactUsSubmit} disabled={submitDisabled} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            {/* <div className='col-sm-12 col-md-12 col-lg-6 p-0'> */}
                            <div className='col-sm-12 col-md-12 col-lg-6 p-0 order-sm-1 order-md-2'>
                                <img src={Sec2OfficeImage} alt='...' className='img-fluid' />
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <Footer display={false} />
        </>

    );
};

export default ContactUs;