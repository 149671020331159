import '../assets/css/custom.css';
import Home1 from '../assets/img/Home1.png';
import HomeMob from '../assets/img/Home1-Mobile.png';
import Travel from '../assets/img/Travel.png';
import Hospitality from '../assets/img/Hospitality.png';
import Events from '../assets/img/Events.png';
import Retail from '../assets/img/Retail.png';
import TechDriven from '../assets/img/tech-driven.png';
import Advanced from '../assets/img/Advanced.png';
import SpeedToMarket from '../assets/img/Speed-to-market.png';
import ScalableSolutions from '../assets/img/scalable-solutions.jpg';
import CaseStudy1 from '../assets/img/case-study-1.png';
import CaseStudy2 from '../assets/img/case-study-2.png';
import CaseStudy3 from '../assets/img/case-study-3.png';
import ArrowRightBlue from '../assets/img/arrow-right-blue.svg';
import ArrowRightWhite from '../assets/img/arrow-right-white.svg';

import 'animate.css';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import Header from './HeaderFooter/header';
import Footer from './HeaderFooter/footer';
import { useHistory } from 'react-router-dom';
import CustomButton from './Button/CustomButton';
import { useState } from 'react';



const Home = () => {


    const history = useHistory();

    const [readMore, setReadMore] = useState(false);

    const BackToTop = () => {
        window.scrollTo(0, 0);
    }

    const onProductDevelopment = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/product-development');
    }

    const onBlog1 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog/discovering-india-top-10-travel-destinations-that-capture-the-essence-of-diversity');
    }

    const onBlog2 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog/transformative-travel-how-ai-is-revolutionizing-the-way-we-explore-the-world');
    }

    const onBlog3 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog/navigating-india-essential-tips-for-a-smooth-and-enjoyable-journey');
    }

    // window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth'
    // });

    const onReadMoreClicked = (e) => {
        e.preventDefault();
        setReadMore(!readMore);
    }


    return (
        <>
            <Header />


            <div className='content-wrap banner-adjust-navbar'>


                {/* WEB & iPad */}
                <div className="home-section-1-container d-none d-md-block">
                    <img src={Home1} alt="Norway" className='w-100 home-section-1-image' />
                    <div className='container'>
                        <div className="home-section-1-text-block">
                            <div className="animate__animated animate__fadeInLeft animate__delay-1s">
                                {/* <AnimationOnScroll animateIn="animate__fadeInLeft"> */}
                                <p className='title-sec-1'>Turning Ideas into Destinations</p>
                                <p className='sub-title-sec-1'>Your Gateway to Next-Level Travel Tech</p>
                            </div>
                            {/* <button type="button" onClick={onProductDevelopment} className="btn btn-info">Know More</button> */}


                            <CustomButton customButtonClass='mt-5' onCustomClick={onProductDevelopment} text="Know More" faIcon="fa-solid fa-arrow-left" />

                            {/* </AnimationOnScroll> */}
                        </div>
                    </div>
                </div>
                {/* Mobile */}
                <div className="home-section-1-container d-block d-sm-none">
                    <img src={HomeMob} alt="Norway" className='w-100 home-section-1-image  ' />

                    <div className="home-section-1-text-block-mob p-3 ">
                        <p>Turning Ideas into Destinations</p>
                        <h4>Your Gateway to Next-Level Travel Tech</h4>
                        <CustomButton onCustomClick={onProductDevelopment} text="Know More" faIcon="fa-solid fa-arrow-left" />
                    </div>
                </div>

                <div className="home-section-2-container ">
                    <div className='container'>

                        <div className="row mb-5">
                            <div className="col-xs-12 col-md-6 col-lg-5">
                                <h1>Who We Are</h1>
                                <h3>Tailored Adventures For Seamless Solutions</h3>
                            </div>
                            <div className="col-xs-12 col-md-6 col-lg-6 pt-5 ms-auto">

                                {readMore === false ?
                                    <>
                                        <p>
                                            In A World Driven By Constant Change, Tass Technologies Stands As A Beacon Of Progress, Embodying The Fusion
                                            Of Innovation And Industry Excellence. We Open Our Doors To A Realm Where Possibilities Are Limitless, And Where
                                            Businesses Can Thrive On The Cusp Of Technological Evolution.
                                        </p>
                                        {/* <a onClick={onReadMoreClicked}>Read More</a> */}

                                        <a href="/" onClick={onReadMoreClicked} role="button">
                                            Read More
                                        </a>

                                    </>
                                    :
                                    <>
                                        <p>
                                            In A World Driven By Constant Change, Tass Technologies Stands As A Beacon Of Progress, Embodying The Fusion
                                            Of Innovation And Industry Excellence. We Open Our Doors To A Realm Where Possibilities Are Limitless, And Where
                                            Businesses Can Thrive On The Cusp Of Technological Evolution.
                                            In the dynamic intersection of technology and industry, TASS Technologies takes the lead. We specialize in the art of crafting tailored solutions, each a masterpiece designed to reshape how businesses operate in the realms of travel, hospitality, events, and retail. Our dedication to specialization allows us to delve deep into the unique challenges and opportunities within these industries, ensuring that our clients receive solutions perfectly attuned to their needs.
                                        </p>
                                        {/* <a onClick={onReadMoreClicked}>Read Less</a> */}
                                        <a href="/" onClick={onReadMoreClicked} role="button">
                                            Read Less
                                        </a>
                                    </>
                                }
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <h4>Focused Spheres</h4>
                            </div>
                        </div>


                        <div className='row mt-2'>
                            <div className="col-lg-3     col-md-6 col-sm-6 col-xs-3 mb-4">
                                <AnimationOnScroll animateIn="animate__fadeInBottomLeft">
                                    <div className="card section-2-card">
                                        <img src={Travel} className="home-section-2-card" alt="..." />
                                        <div className="card-body">
                                            <h5 className="section-2-card-title">Travel</h5>
                                            {/* <p className="section-2-card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras magna metus, suscipit </p> */}
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-3 mb-4">
                                {/* <AnimationOnScroll animateIn="animate__fadeInUp"> */}
                                <AnimationOnScroll animateIn="animate__fadeInUp">
                                    <div className="card section-2-card">
                                        <img src={Hospitality} className="home-section-2-card" alt="..." />
                                        <div className="card-body">
                                            <h5 className="section-2-card-title">Hospitality</h5>
                                            {/* <p className="section-2-card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras magna metus, suscipit </p> */}
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-3 mb-4">
                                {/* <AnimationOnScroll animateIn="animate__fadeInUp"> */}
                                <AnimationOnScroll animateIn="animate__fadeInUp">

                                    <div className="card section-2-card">
                                        <img src={Events} className="home-section-2-card" alt="..." />
                                        <div className="card-body">
                                            <h5 className="section-2-card-title">Events</h5>
                                            {/* <p className="section-2-card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras magna metus, suscipit </p> */}
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                            <div className="col-lg-3 col-md-6 col-sm-6 col-xs-3 mb-4">

                                <AnimationOnScroll animateIn="animate__fadeInBottomRight">

                                    <div className="card section-2-card">
                                        <img src={Retail} className=" home-section-2-card" alt="..." />
                                        <div className="card-body">
                                            <h5 className="section-2-card-title">Retail</h5>
                                            {/* <p className="section-2-card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras magna metus, suscipit </p> */}
                                        </div>
                                    </div>
                                </AnimationOnScroll>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="home-section-3-container pb-5">
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12 seaction-3-heading py-3'>
                                <h4>The TASS Advantage</h4>
                            </div>
                        </div>

                        <div className="row d-none d-lg-flex">
                            <div className="col-lg-3">
                                <div className="special-card">
                                    <div className="content">
                                        <div className="front">
                                            <img className="profile" width="100%" src={TechDriven} alt="Ronaldo" />
                                            <div className="des">
                                                <AnimationOnScroll animateIn="animate__zoomIn">
                                                    <h1>T</h1>
                                                    <h4>Tech Driven</h4>
                                                </AnimationOnScroll>
                                            </div>
                                        </div>
                                        <div className="back from-left">
                                            <div className="des">
                                                <h1>T</h1>
                                                <h4>Tech Driven</h4>
                                                <p>Pioneering product development with a tech-driven approach, we craft innovative solutions to elevate businesses and exceed industry expectations</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className="special-card">
                                    <div className="content">
                                        <div className="front">
                                            <img className="profile" width="100%" src={Advanced} alt="Ronaldo" />
                                            <div className="des">
                                                <AnimationOnScroll animateIn="animate__zoomIn">
                                                    <h1>A</h1>
                                                    <h4>Advanced</h4>
                                                </AnimationOnScroll>
                                            </div>
                                        </div>
                                        <div className="back from-bottom">
                                            <div className="des">
                                                <h1>A</h1>
                                                <h4>Advanced</h4>
                                                <p>Revolutionizing products through an advanced tech approach, we engineer cutting-edge solutions for unparalleled performance and future-proof success</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className="special-card">
                                    <div className="content">
                                        <div className="front">
                                            <img className="profile" src={SpeedToMarket} alt="Ronaldo" />
                                            <div className="des">
                                                <AnimationOnScroll animateIn="animate__zoomIn">
                                                    <h1>S</h1>
                                                    <h4>Speed-to-market</h4>
                                                </AnimationOnScroll>
                                            </div>
                                        </div>
                                        <div className="back from-bottom">
                                            <div className="des">
                                                <h1>S</h1>
                                                <h4>Speed-to-market</h4>
                                                <p>Accelerating success with a speed-to-market approach, we swiftly transform ideas into market-ready solutions, ensuring your competitive edge is maintained</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3">
                                <div className="special-card">
                                    <div className="content">
                                        <div className="front">
                                            <img className="profile" src={ScalableSolutions} alt="Ronaldo" />
                                            <div className="des">
                                                <AnimationOnScroll animateIn="animate__zoomIn">
                                                    <h1>S</h1>
                                                    <h4>Scalable Solutions</h4>
                                                </AnimationOnScroll>
                                            </div>
                                        </div>
                                        <div className="back from-right">
                                            <div className="des">
                                                <h1>S</h1>
                                                <h4>Scalable Solutions</h4>
                                                <p>Crafting scalable solutions, we ensure your product evolves seamlessly with growing demands, guaranteeing sustained success in dynamic markets</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>


                    {/* iPad */}
                    <div className='container  d-none d-md-block d-lg-none'>

                        <div className='row home-section-3-all-card'>
                            <div id="carouselExampleControlsNoTouching3" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active" data-bs-interval="1500">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="card border-0 home-section-3-cards">
                                                    <div className="special-card">
                                                        <div className="content">
                                                            <div className="front">
                                                                <img className="profile" width="100%" src={TechDriven} alt="Ronaldo" />
                                                                <div className="des">
                                                                    <h1>T</h1>
                                                                    <h4>Tech Driven</h4>
                                                                </div>
                                                            </div>
                                                            <div className="back from-left">
                                                                <div className="des">
                                                                    <h1>T</h1>
                                                                    <h4>Tech Driven</h4>
                                                                    <p>Pioneering product development with a tech-driven approach, we craft innovative solutions to elevate businesses and exceed industry expectations</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="card border-0 home-section-3-cards">
                                                    <div className="special-card">
                                                        <div className="content">
                                                            <div className="front">
                                                                <img className="profile" width="100%" src={Advanced} alt="Ronaldo" />
                                                                <div className="des">
                                                                    <h1>A</h1>
                                                                    <h4>Advanced</h4>
                                                                </div>
                                                            </div>
                                                            <div className="back from-bottom">
                                                                <div className="des">
                                                                    <h1>A</h1>
                                                                    <h4>Advanced</h4>
                                                                    <p>Revolutionizing products through an advanced tech approach, we engineer cutting-edge solutions for unparalleled performance and future-proof success</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-bs-interval="1500">
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className="card border-0 home-section-3-cards">
                                                    <div className="special-card">
                                                        <div className="content">
                                                            <div className="front">
                                                                <img className="profile" src={SpeedToMarket} alt="Ronaldo" />
                                                                <div className="des">
                                                                    <h1>S</h1>
                                                                    <h4>Speed-to-market</h4>
                                                                </div>
                                                            </div>
                                                            <div className="back from-bottom">
                                                                <div className="des">
                                                                    <h1>S</h1>
                                                                    <h4>Speed-to-market</h4>
                                                                    <p>Accelerating success with a speed-to-market approach, we swiftly transform ideas into market-ready solutions, ensuring your competitive edge is maintained</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="card border-0 home-section-3-cards">
                                                    <div className="special-card">
                                                        <div className="content">
                                                            <div className="front">
                                                                <img className="profile" src={ScalableSolutions} alt="Ronaldo" />
                                                                <div className="des">
                                                                    <h1>S</h1>
                                                                    <h4>Scalable Solutions</h4>
                                                                </div>
                                                            </div>
                                                            <div className="back from-right">
                                                                <div className="des">
                                                                    <h1>S</h1>
                                                                    <h4>Scalable Solutions</h4>
                                                                    <p>Crafting scalable solutions, we ensure your product evolves seamlessly with growing demands, guaranteeing sustained success in dynamic markets</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching3" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching3" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>


                    {/* MOBILE */}
                    <div className='container d-block d-sm-none pb-4'>
                        <div className='row home-section-3-all-card'>
                            <div id="carouselExampleControlsNoTouching" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active" data-bs-interval="2000">
                                        {/* <div className='col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-5'> */}
                                        <div className="card border-0 home-section-3-cards">
                                            <div className="special-card">
                                                <div className="content">
                                                    <div className="front">
                                                        <img className="profile" width="100%" src={TechDriven} alt="Ronaldo" />
                                                        <div className="des">
                                                            <h1>T</h1>
                                                            <h4>Tech Driven</h4>
                                                        </div>
                                                    </div>
                                                    <div className="back from-left">
                                                        <div className="des">
                                                            <h1>T</h1>
                                                            <h4>Tech Driven</h4>
                                                            <p>Pioneering product development with a tech-driven approach, we craft innovative solutions to elevate businesses and exceed industry expectations</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-bs-interval="2000">
                                        {/* <div className='col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-5'> */}
                                        <div className="card border-0 home-section-3-cards">
                                            <div className="special-card">
                                                <div className="content">
                                                    <div className="front">
                                                        <img className="profile" width="100%" src={Advanced} alt="Ronaldo" />
                                                        <div className="des">
                                                            <h1>A</h1>
                                                            <h4>Advanced</h4>
                                                        </div>
                                                    </div>
                                                    <div className="back from-bottom">
                                                        <div className="des">
                                                            <h1>A</h1>
                                                            <h4>Advanced</h4>
                                                            <p>Revolutionizing products through an advanced tech approach, we engineer cutting-edge solutions for unparalleled performance and future-proof success</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                    <div className="carousel-item" data-bs-interval="2000">
                                        {/* <div className='col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-5'> */}
                                        <div className="card border-0 home-section-3-cards">
                                            <div className="special-card">
                                                <div className="content">
                                                    <div className="front">
                                                        <img className="profile" src={SpeedToMarket} alt="Ronaldo" />
                                                        <div className="des">
                                                            <h1>S</h1>
                                                            <h4>Speed-to-market</h4>
                                                        </div>
                                                    </div>
                                                    <div className="back from-bottom">
                                                        <div className="des">
                                                            <h1>S</h1>
                                                            <h4>Speed-to-market</h4>
                                                            <p>Accelerating success with a speed-to-market approach, we swiftly transform ideas into market-ready solutions, ensuring your competitive edge is maintained</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                    <div className="carousel-item" data-bs-interval="2000">
                                        {/* <div className='col-lg-3 col-md-4 col-sm-12 col-xs-12 mb-5'> */}
                                        <div className="card  border-0 home-section-3-cards">
                                            <div className="special-card">
                                                <div className="content">
                                                    <div className="front">
                                                        <img className="profile" src={ScalableSolutions} alt="Ronaldo" />
                                                        <div className="des">
                                                            <h1>S</h1>
                                                            <h4>Scalable Solutions</h4>
                                                        </div>
                                                    </div>
                                                    <div className="back from-right">
                                                        <div className="des">
                                                            <h1>S</h1>
                                                            <h4>Scalable Solutions</h4>
                                                            <p>Crafting scalable solutions, we ensure your product evolves seamlessly with growing demands, guaranteeing sustained success in dynamic markets</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='home-section-4-container'>
                    <div className='container pb-5'>
                        <div className='row'>
                            <p className='section-4-heading-1 mt-5'>Insights</p>
                        </div>
                        <div className='row mb-2'>
                            <AnimationOnScroll animateIn="animate__fadeIn">
                                <h3 className='section-4-heading-2'>Speaking Of Our Significant <br /> Deployments</h3>
                            </AnimationOnScroll>
                        </div>
                        {/* WEB */}
                        <div className='row home-section-4-all-cards d-none d-md-flex'>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 py-3'>
                                <div className="card  border-0 section-4-card">
                                    <img src={CaseStudy1} className="home-section-4-card" alt="..." />
                                    <div className="home-section-4-text-block">
                                        <AnimationOnScroll animateIn="animate__zoomIn">

                                            <p>Blog</p>
                                            <h4>Discovering India: Top 10 Travel Destinations That Capture the Essence of Diversity</h4>
                                        </AnimationOnScroll>
                                        <button className='readMoreBtn' onClick={onBlog1}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 py-3'>
                                <div className="card  border-0 section-4-card">
                                    <img src={CaseStudy2} className="home-section-4-card" alt="..." />
                                    <div className="home-section-4-text-block">
                                        <AnimationOnScroll animateIn="animate__zoomIn">

                                            <p>Blog</p>
                                            <h4>Transformative Travel: How AI is Revolutionizing the Way We Explore the World</h4>
                                        </AnimationOnScroll>
                                        <button className='readMoreBtn' onClick={onBlog2}>Read More <img src={ArrowRightWhite} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 py-3'>
                                <div className="card  border-0 section-4-card">
                                    <img src={CaseStudy3} className="home-section-4-card" alt="..." />
                                    <div className="home-section-4-text-block">
                                        <AnimationOnScroll animateIn="animate__zoomIn">

                                            <p>Blog</p>
                                            <h4>Navigating India: Essential Tips for a Smooth and Enjoyable Journey</h4>
                                        </AnimationOnScroll>
                                        <button className='readMoreBtn' onClick={onBlog3}>Read More <img src={ArrowRightWhite} alt="..." /></button>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    {/* MOBILE */}
                    <div className='container d-block d-sm-none pb-4'>
                        <div className='row home-section-4-all-cards'>
                            <div id="carouselExampleControlsNoTouching1" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active" data-bs-interval="2000">
                                        <div className="card  border-0">
                                            <img src={CaseStudy1} className="home-section-4-card" alt="..." />
                                            <div className="home-section-4-text-block">
                                                <p>Blog</p>
                                                <h4>Discovering India: Top 10 Travel Destinations That Capture the Essence of Diversity</h4>
                                                <button className='readMoreBtn' onClick={onBlog1}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-bs-interval="2000">
                                        <div className="card  border-0">
                                            <img src={CaseStudy2} className="home-section-4-card" alt="..." />
                                            <div className="home-section-4-text-block">
                                                <p>Blog</p>
                                                <h4>Transformative Travel: How AI is Revolutionizing the Way We Explore the World</h4>
                                                <button className='readMoreBtn' onClick={onBlog2}>Read More <img src={ArrowRightWhite} alt="..." /></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="carousel-item" data-bs-interval="2000">
                                        <div className="card  border-0">
                                            <img src={CaseStudy3} className="home-section-4-card" alt="..." />
                                            <div className="home-section-4-text-block">
                                                <p>Blog</p>
                                                <h4>Navigating India: Essential Tips for a Smooth and Enjoyable Journey</h4>
                                                <button className='readMoreBtn' onClick={onBlog3}>Read More <img src={ArrowRightWhite} alt="..." /></button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching1" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching1" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>
                        </div>
                    </div>

                </div>

                <Footer display={true} />

            </div>


        </>
    );
};

export default Home;