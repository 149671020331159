import '../assets/css/custom.css';

import Footer from './HeaderFooter/footer';
import Header from './HeaderFooter/header';

import sec1Web from '../assets/img/product-development/pd-sec-1-web.png'
import sec1iPad from '../assets/img/product-development/pd-sec-1-ipad.png'
import sec1Mob from '../assets/img/product-development/pd-sec-1-mobile.png'

import clipArt1 from '../assets/img/product-development/clipart/1.svg'
import clipArt2 from '../assets/img/product-development/clipart/2.svg'
import clipArt3 from '../assets/img/product-development/clipart/3.svg'
import clipArt4 from '../assets/img/product-development/clipart/4.svg'
import clipArt5 from '../assets/img/product-development/clipart/5.svg'
import clipArt6 from '../assets/img/product-development/clipart/6.svg'
import clipArt7 from '../assets/img/product-development/clipart/7.svg'
import clipArt8 from '../assets/img/product-development/clipart/8.svg'
import clipArt9 from '../assets/img/product-development/clipart/9.svg'
import clipArt10 from '../assets/img/product-development/clipart/10.svg'
import clipArt11 from '../assets/img/product-development/clipart/11.svg'
import clipArt12 from '../assets/img/product-development/clipart/12.svg'

import sec3img1 from '../assets/img/product-development/section-3-1.png'
import sec3img2 from '../assets/img/product-development/section-3-2.png'
import sec3img3 from '../assets/img/product-development/section-3-3.png'

import Blog1 from '../assets/img/blog/blog-1.png';
import Blog2 from '../assets/img/blog/blog-2.png';
import Blog3 from '../assets/img/blog/blog-3.png';

import ArrowRightBlue from '../assets/img/pg-about-us-arrow-right-blue.png';
import { AnimationOnScroll } from 'react-animation-on-scroll/dist/js';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';

const ProductDevelopment = () => {

    const [activeTab, setActiveTab] = useState(1);
    const history = useHistory();

    const handleClick = (e, tab) => {
        e.preventDefault();
        setActiveTab(tab);
    }

    const BackToTop = () => {
        window.scrollTo(0, 0);
    }

    const onBlog1 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog/discovering-india-top-10-travel-destinations-that-capture-the-essence-of-diversity');
    }

    const onBlog2 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog/transformative-travel-how-ai-is-revolutionizing-the-way-we-explore-the-world');
    }

    const onBlog3 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog/navigating-india-essential-tips-for-a-smooth-and-enjoyable-journey');
    }

    let productData = [
        { title: "Product Ideation & Conceptualization", list: ["Brainstorming and idea generation.", "Market research and feasibility studies"], img: clipArt1 },
        { title: "Testing & Quality Assurance", list: ["Conducting unit testing and system testing.", "Ensuring product quality and reliability."], img: clipArt2 },
        { title: "Integration Services", list: ["Integrating software and hardware components.", "API development and integration."], img: clipArt3 },
        { title: "Mobile App Development", list: ["Developing mobile applications for various platforms.", "Ensuring cross-platform compatibility."], img: clipArt4 },
        { title: "IoT (Internet of Things) Development", list: ["Connecting devices and sensors.", "Implementing IoT solutions."], img: clipArt5 },
        { title: "Data Analytics & Business Intelligence", list: ["Implementing analytics solutions for data-driven insights.", "Business intelligence and reporting."], img: clipArt6 },
        { title: "Cybersecurity", list: ["Implementing security measures to protect the product.", "Conducting security audits and risk assessments."], img: clipArt7 },
        { title: "Scalability & Performance Optimization", list: ["Ensuring the product can scale with growing demand.", "Optimizing performance for efficiency."], img: clipArt8 },
        { title: "Maintenance & Support", list: ["Providing ongoing support and maintenance services.", "Addressing issues and implementing updates."], img: clipArt9 },
        { title: "Regulatory Compliance", list: ["Ensuring the product complies with relevant regulations and standards.", "Facilitating certification processes."], img: clipArt10 },
        { title: "Project Management", list: ["Overseeing the entire product development lifecycle.", "Agile or other project management methodologies."], img: clipArt11 },
        { title: "Consulting & Advisory Services", list: ["Offering expertise and guidance on technology choices.", "Strategic planning for product development."], img: clipArt12 },

    ]


    return (
        <>
            <Header />

            <div className='content-wrap  banner-adjust-navbar'>

                {/* WEB */}
                <div className='prodect-devlopment-section-1-container d-none d-lg-block'>
                    <img src={sec1Web} alt='...' className='w-100 prodect-devlopment-section-1-image' />
                    <div className='container'>
                        <div className='prodect-devlopment-section-1-text-block'>
                            <div className="animate__animated animate__fadeInLeft animate__delay-1s">
                                <p>From Concept to Creation</p>
                                <h4>Your Product's Journey Starts Here</h4>
                            </div>
                        </div>
                    </div>
                </div>

                {/* iPad */}
                <div className='prodect-devlopment-section-1-container d-none d-md-block d-lg-none'>
                    <img src={sec1iPad} alt='...' className='w-100 prodect-devlopment-section-1-image-ipad' />
                    <div className='container'>
                        <div className='prodect-devlopment-section-1-text-block'>
                            <div className="animate__animated animate__fadeInLeft animate__delay-1s">
                                <p>From Concept to Creation</p>
                                <h4>Your Product's Journey Starts Here</h4>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile */}
                <div className='prodect-devlopment-section-1-container d-block d-sm-none'>
                    <img src={sec1Mob} alt='...' className='w-100 prodect-devlopment-section-1-image' />
                    <div className='prodect-devlopment-section-1-text-block p-4 '>
                        <div className="animate__animated animate__fadeInLeft animate__delay-1s">
                            <p>From Concept to Creation</p>
                            <h4>Your Product's Journey Starts Here</h4>
                        </div>
                    </div>
                </div>

                <div className='prodect-devlopment-section-2-container py-5'>
                    <div className='container'>
                        <div className='row'>
                            <p className='prodect-devlopment-section-2-heading pb-4'>Strategic Solutions in Product <br /> Development Excellence</p>
                        </div>

                        <div className='row'>
                            {
                                productData.map((d, index) => <div className='col-sm-12 col-md-4 col-lg-3 mb-4' key={`product-${index}`}>
                                    <div className='product-card'>
                                        <img src={d.img} alt="..." />
                                        <h1 className="title">{d.title}</h1>
                                        <ul>
                                            {d.list.map((sd, ind) => <li key={`sublsit-${ind}`}>{sd}</li>)}
                                        </ul>
                                    </div>
                                </div>
                                )
                            }
                        </div>

                    </div>
                </div>

                {/* web-ipad */}
                <div className='prodect-devlopment-section-3-container py-5  d-none d-md-block'>
                    <div className='container my-5'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    <span className="custom-title-3">The TASS Difference</span>
                                    <p className='custom-title-6'>Innovate. Collaborate. Excel</p>
                                </AnimationOnScroll>
                            </div>
                            <div className='col-lg-8'>
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    <p className='custom-desc-6'>At TASS, we redefine the landscape of product development, offering a distinctive blend of innovation, collaboration, and excellence. Our Unique Selling Proposition lies in our unwavering commitment to transforming concepts into extraordinary, market-leading products.</p>
                                </AnimationOnScroll>
                            </div>
                        </div>
                        <div className=' d-flex  justify-content-center p-4 pt-5'>
                            <button className={`custom-tab-btn ${activeTab === 1 && 'active-btn'}`} onClick={(e) => handleClick(e, 1)} >Innovation at the Core</button>
                            <button className={`custom-tab-btn ${activeTab === 2 && 'active-btn'}`} onClick={(e) => handleClick(e, 2)}>Collaboration that Transcends Boundaries</button>
                            <button className={`custom-tab-btn ${activeTab === 3 && 'active-btn'}`} onClick={(e) => handleClick(e, 3)}>Excellence in Every Detail</button>
                        </div>


                        {activeTab === 1 &&
                            <div className='row product-dev-sec-3-wrapper'>
                                <div className="col-md-6 p-0">
                                    <img src={sec3img1} alt='...' className='w-100 pd-sec-3-img' />
                                </div>
                                <div className="col-md-6 product-dev-text">
                                    <span className='custom-title-2'>Innovation at the Core</span>
                                    <p className='custom-title-10'>Our company thrives on innovation. We don't just follow industry trends; we set them. From cutting-edge technologies to forward-thinking design, we infuse every project with a spirit of creativity and originality. Our team of visionaries ensures that your product stands out in a crowded market, giving you a competitive edge that resonates with your target audience.</p>
                                </div>
                            </div>
                        }


                        {activeTab === 2 &&
                            <div className='row product-dev-sec-3-wrapper'>
                                <div className="col-md-6 p-0">
                                    <img src={sec3img2} alt='...' className='w-100 pd-sec-3-img' />
                                </div>
                                <div className="col-md-6 product-dev-text">
                                    <span className='custom-title-2'>Collaboration that Transcends Boundaries</span>
                                    <p className='custom-title-10'>True innovation is born through collaboration. At TASS, we believe in working hand-in-hand with our clients, forging a partnership that goes beyond traditional client-agency relationships. We listen intently to your ideas, understand your goals, and collaborate seamlessly to bring your vision to life. Together, we navigate the journey from concept to creation, ensuring that the end result not only meets but exceeds your expectations.</p>
                                </div>
                            </div>
                        }


                        {activeTab === 3 &&
                            <div className='row product-dev-sec-3-wrapper'>
                                <div className="col-md-6 p-0">
                                    <img src={sec3img3} alt='...' className='w-100 pd-sec-3-img' />
                                </div>
                                <div className="col-md-6 product-dev-text">
                                    <span className='custom-title-2'>Excellence in Every Detail</span>
                                    <p className='custom-title-10'>Our pursuit of excellence is relentless. From meticulous planning to flawless execution, we uphold the highest standards in product development. We combine state-of-the-art technology with a passion for perfection, resulting in products that not only function flawlessly but also deliver an exceptional user experience. Our commitment to excellence extends to every phase of development, ensuring that your product not only meets industry standards but sets new benchmarks.</p>
                                </div>
                            </div>
                        }
                    </div>
                </div>

                {/* mobile */}
                <div className='prodect-devlopment-section-3-container py-5 d-block d-sm-none'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    <span className="custom-title-3">The TASS Difference</span>
                                    <p className='custom-title-6'>Innovate. Collaborate. Excel</p>
                                </AnimationOnScroll>
                            </div>
                            <div className='col-lg-8'>
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    <p className='custom-desc-6'>At TASS, we redefine the landscape of product development, offering a distinctive blend of innovation, collaboration, and excellence. Our Unique Selling Proposition lies in our unwavering commitment to transforming concepts into extraordinary, market-leading products.</p>
                                </AnimationOnScroll>
                            </div>
                        </div>
                        {/* <div className=' d-flex  justify-content-center p-4'>
                            <button className={`custom-tab-btn ${activeTab === 1 && 'active-btn'}`} onClick={(e) => handleClick(e, 1)} >Innovation at the Core</button>
                            <button className={`custom-tab-btn ${activeTab === 2 && 'active-btn'}`} onClick={(e) => handleClick(e, 2)}>Collaboration that Transcends Boundaries</button>
                            <button className={`custom-tab-btn ${activeTab === 3 && 'active-btn'}`} onClick={(e) => handleClick(e, 3)}>Excellence in Every Detail</button>
                        </div> */}


                        <div className='row product-dev-sec-3-wrapper my-5'>
                            <div className="col-md-6 p-0">
                                <img src={sec3img1} alt='...' className='w-100 pd-sec-3-img' />
                            </div>
                            <div className="col-md-6 product-dev-text">
                                <span className='custom-title-2'>Innovation at the Core</span>
                                <p className='custom-title-10'>Our company thrives on innovation. We don't just follow industry trends; we set them. From cutting-edge technologies to forward-thinking design, we infuse every project with a spirit of creativity and originality. Our team of visionaries ensures that your product stands out in a crowded market, giving you a competitive edge that resonates with your target audience.</p>
                            </div>
                        </div>


                        <div className='row product-dev-sec-3-wrapper my-5'>
                            <div className="col-md-6 p-0">
                                <img src={sec3img2} alt='...' className='w-100 pd-sec-3-img' />
                            </div>
                            <div className="col-md-6 product-dev-text">
                                <span className='custom-title-2'>Collaboration that Transcends Boundaries</span>
                                <p className='custom-title-10'>True innovation is born through collaboration. At TASS, we believe in working hand-in-hand with our clients, forging a partnership that goes beyond traditional client-agency relationships. We listen intently to your ideas, understand your goals, and collaborate seamlessly to bring your vision to life. Together, we navigate the journey from concept to creation, ensuring that the end result not only meets but exceeds your expectations.</p>
                            </div>
                        </div>


                        <div className='row product-dev-sec-3-wrapper my-5'>
                            <div className="col-md-6 p-0">
                                <img src={sec3img3} alt='...' className='w-100 pd-sec-3-img' />
                            </div>
                            <div className="col-md-6 product-dev-text">
                                <span className='custom-title-2'>Excellence in Every Detail</span>
                                <p className='custom-title-10'>Our pursuit of excellence is relentless. From meticulous planning to flawless execution, we uphold the highest standards in product development. We combine state-of-the-art technology with a passion for perfection, resulting in products that not only function flawlessly but also deliver an exceptional user experience. Our commitment to excellence extends to every phase of development, ensuring that your product not only meets industry standards but sets new benchmarks.</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='about-us-section-5-container py-5'>
                    <div className='container'>
                        <div className='row'>
                            <p className='about-us-section-5-heading-1'>Insights</p>
                        </div>
                        <div className='row mb-2'>
                            <AnimationOnScroll animateIn="animate__zoomIn">
                                <h3 className='about-us-section-5-heading-2'>Speaking Of Our Significant <br /> Deployments</h3>
                            </AnimationOnScroll>
                        </div>
                        <div className='row about-us-section-5-all-cards py-3'>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog1} className="" alt="..." />

                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Discovering India: Top 10 Travel Destinations That Capture the Essence of Diversity</h5>
                                        <p className="about-us-section-5-card-text">India, a land of vibrant colors, rich history, diverse cultures, and breathtaking landscapes...</p>
                                        <button className='readMoreBtn' onClick={onBlog1}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog2} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Transformative Travel: How AI is Revolutionizing the Way We Explore the World</h5>
                                        <p className="about-us-section-5-card-text">In the digital age, Artificial Intelligence (AI) has become a driving force behind transformative...</p>
                                        <button className='readMoreBtn' onClick={onBlog2}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog3} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Navigating India: Essential Tips for a Smooth and Enjoyable Journey</h5>
                                        <p className="about-us-section-5-card-text">Traveling to India is an exhilarating experience that offers a tapestry of diverse cultures...</p>
                                        <button className='readMoreBtn' onClick={onBlog3}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <Footer display={true} text="Ready to bring your unique vision to life? It all begins with a simple click or call." />
        </>

    );
};

export default ProductDevelopment;