import '../../assets/css/custom.css';
import logoFooter from '../../assets/img/logoFooter.png';
import { useHistory } from 'react-router-dom';
import CustomButton from '../Button/CustomButton';


const Footer = (props) => {

    const history = useHistory();

    const BackToTop = () => {
        window.scrollTo(0, 0);
    }

    const onAboutUs = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/about-us');
    }

    const onProductDevelopment = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/product-development');
    }

    const onBlog = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog');
    }

    const onContactUs = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/contact-us');
    }


    return (
        <>
            <div className='home-section-5-container'>
                {props.display &&
                    <div className="container section-5-align">
                        <div className=" row align-content-center ml-1 mr-1 ml-md-5 mr-md-5 ">
                            <div className="col-lg-12 section-5-align">
                                <div className="contact">
                                    <h1 className="sectionContact-title text-center ">
                                        {/* Your Future, Our Expertise - Act Now */}
                                        {/* {props.text ? props.text : "Your Future, Our Expertise - Act Now"} */}
                                        <div className='d-none d-md-block'>
                                            <div dangerouslySetInnerHTML={{ __html: props.text ? props.text : 'Your Future, Our Expertise<br>- Act Now' }} />
                                        </div>

                                        <div className='d-block d-sm-none'>
                                            {props.text ? props.text : "Your Future, Our Expertise - Act Now"}
                                        </div>

                                        {/* <br /> */}
                                    </h1>
                                    <div className="text-center mt-4">
                                        {/* <button onClick={onContactUs} type="button" className="btn btn-info">Contact Us</button> */}
                                        <CustomButton onCustomClick={onContactUs} text="Contact Us" faIcon="fa-solid fa-phone" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>


            <footer className={`footer ${props.display ? '' : 'pt-5'}`}>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className=" col-xs-12 col-sm-12 col-md-2 py-3">
                            <img className='logo' src={logoFooter} alt='Tass Logo' />
                        </div>

                        {/* <div className='col-sm-12  col-md-2 col-lg-2 py-3'>
                            About Us
                        </div>
                        <div className='col-sm-12 col-md-2 col-lg-2 py-3'>
                            Our Services
                        </div>
                        <div className='col-sm-12 col-md-2 col-lg-2 py-3'>
                            Use Cases
                        </div>
                        <div className='col-sm-12 col-md-2 col-lg-2 py-3'>
                            Contact Us
                        </div> */}



                        <div className='col-sm-12 col-md-auto py-3'>
                            <ul className='footer-links'>
                                <li className='footer-link-item'>
                                    <a className="nav-link" href='/' onClick={onAboutUs}>
                                        About Us
                                    </a>
                                </li>
                                <li className='footer-link-item'>
                                    <a className="nav-link" href='/' onClick={onProductDevelopment}>
                                        Product Development
                                    </a>
                                </li>
                                <li className='footer-link-item'>
                                    <a className="nav-link" href='/' onClick={onBlog}>
                                        Insights
                                    </a>
                                </li>
                                <li className='footer-link-item me-0'>
                                    <a className="nav-link" href='/' onClick={onContactUs}>
                                        Contact Us
                                    </a>
                                </li>
                            </ul>
                        </div>


                        {/* <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <div className="card bg-transparent">
                                <div className="card-body">
                                    <AnimationOnScroll animateIn="animate__slideInUp">

                                        <h5 className="footer-card-title">Lorem ipsum dolor</h5>
                                        <p className="footer-card-text">Nunc felis lacus, cursus quis nulla ut, suscipit semper ante. Donec lacus arcu, commodo ut rhoncus sit amet, elementum id tortor.</p>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <div className="card bg-transparent">
                                <div className="card-body">
                                    <AnimationOnScroll animateIn="animate__slideInUp">

                                        <h5 className="footer-card-title">Lorem ipsum dolor</h5>
                                        <p className="footer-card-text">Nunc felis lacus, cursus quis nulla ut, suscipit semper ante. Donec lacus arcu, commodo ut rhoncus sit amet, elementum id tortor.</p>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12">
                            <div className="card bg-transparent">
                                <div className="card-body">
                                    <AnimationOnScroll animateIn="animate__slideInUp">

                                        <h5 className="footer-card-title">Lorem ipsum dolor</h5>
                                        <p className="footer-card-text">Nunc felis lacus, cursus quis nulla ut, suscipit semper ante. Donec lacus arcu, commodo ut rhoncus sit amet, elementum id tortor.</p>
                                    </AnimationOnScroll>
                                </div>
                            </div>
                        </div> */}





                    </div>
                    <hr />
                    <div className="row justify-content-between">
                        <div className="col-xs-6 col-sm-6 col-md-10 order-sm-2 order-1">
                            <p className="footer-text">&copy; Copyright TASS All Rights Reserved.</p>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-auto   order-sm-1 order-md-2">
                            {/* <svg xmlns="http://www.w3.org/2000/svg" height="16" width="10" viewBox="0 0 320 512"><path fill="#ffffff" d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" /></svg>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="14" viewBox="0 0 448 512"><path fill="#ffffff" d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" /></svg>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path fill="#ffffff" d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg> */}
                            {/* <a href="/" className='footer-soliacl-link' aria-label="Facebook">
                                <i className="fa-brands fa-facebook-f" aria-hidden="true"></i>
                            </a> */}

                            <a href="https://www.linkedin.com/company/tass-technologies/" className='footer-soliacl-link' aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fa-linkedin-in" aria-hidden="true"></i>
                            </a>

                            {/* <a href="/" className='footer-soliacl-link ms-3' aria-label="Twitter">
                                <i className="fa-brands fa-x-twitter" aria-hidden="true"></i>
                            </a> */}

                        </div>
                    </div>
                </div>
            </footer>
        </>

    );
};

export default Footer;