import React from 'react';
import '../assets/css/custom.css';
import Footer from './HeaderFooter/footer';
import Header from './HeaderFooter/header';
import ContactUs1 from '../assets/img/pg-about-us-1.png'
import ContactUsMobile from '../assets/img/pg-about-us-1-mobile.png'
import section2AboutUs from '../assets/img/pg-about-us-section-2.png'
import experienceLogo from '../assets/img/pg-about-us-experience.png'
import craftedLogo from '../assets/img/pg-about-us-crafted.png'
import satisfactionLogo from '../assets/img/pg-about-us-satisfaction.png'
import sunnyPic from '../assets/img/sunny-patel.png'
import tusharPic from '../assets/img/tushar.png'
import sanjivPic from '../assets/img/sanjiv.png'
import ArrowFounder from '../assets/img/about-us-founder-arrow.svg'
import CloseFounder from '../assets/img/about-us-founder-close.png'


import Blog1 from '../assets/img/blog/blog-1.png';
import Blog2 from '../assets/img/blog/blog-2.png';
import Blog3 from '../assets/img/blog/blog-3.png';

import ArrowRightBlue from '../assets/img/pg-about-us-arrow-right-blue.png';
import { AnimationOnScroll } from 'react-animation-on-scroll/dist/js';

import { useHistory } from 'react-router-dom';

const AboutUs = () => {

    const history = useHistory();

    const BackToTop = () => {
        window.scrollTo(0, 0);
    }

    const onBlog1 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog/discovering-india-top-10-travel-destinations-that-capture-the-essence-of-diversity');
    }

    const onBlog2 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog/transformative-travel-how-ai-is-revolutionizing-the-way-we-explore-the-world');
    }

    const onBlog3 = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog/navigating-india-essential-tips-for-a-smooth-and-enjoyable-journey');
    }


    return (
        <>
            <Header />


            <div className='content-wrap banner-adjust-navbar'>

                {/* WEB */}
                <div className='about-us-section-1-container d-none d-lg-block'>
                    <img src={ContactUs1} alt='...' className='w-100 about-us-section-1-image' />
                    <div className='container'>
                        <div className='about-us-section-1-text-block'>
                            <div className="animate__animated animate__fadeInLeft animate__delay-1s">
                                <p>Turn Ideas into Reality</p>
                                <h4>At TASS, we are the doers! Turning ideas into tangible products and software gives us a certonin push!</h4>
                            </div>
                        </div>
                    </div>
                </div>

                {/* iPad */}
                <div className='about-us-section-1-container d-none d-md-block d-lg-none'>
                    <img src={ContactUs1} alt='...' className='w-100 about-us-section-1-image' />
                    <div className='container'>
                        <div className='about-us-section-1-text-block'>
                            <div className="animate__animated animate__fadeInLeft animate__delay-1s">
                                <p>Turn Ideas into Reality</p>
                                <h4>At TASS, we are the doers! Turning ideas into tangible products and software gives us a certonin push!</h4>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Mobile */}
                <div className='about-us-section-1-container d-block d-sm-none'>
                    <img src={ContactUsMobile} alt='...' className='w-100 about-us-section-1-image' />
                    <div className='about-us-section-1-text-block-mob p-3'>
                        <div className="animate__animated animate__fadeInLeft animate__delay-1s">
                            <p>Turn Ideas into Reality</p>
                            <h4>At TASS, we are the doers! Turning ideas into tangible products and software gives us a certonin push!</h4>
                        </div>
                    </div>
                </div>




                {/* WEB */}

                <div className='about-us-section-2-container pt-4 d-none d-lg-block'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 col-lg-5 px-5 pe-5'>
                                <img className='about-us-section-2-img-web pe-5 ' src={section2AboutUs} alt='...' />
                            </div>
                            <div className='col-md-12 col-lg-7 ps-5'>
                                <h1>About Us</h1>
                                <p className='mt-4'>
                                    Amongst our founders, we have a lovely amalgamation of deep industry
                                    experience in various industries and across multiple disciplines
                                    spanning Technology, Marketing, Sales and Finance. The team at TASS
                                    is a blend of handpicked and homegrown talent calibrated perfectly
                                    to deliver experiences par excellence. At TASS, we believe that the
                                    key to creating extraordinary products lies in the intersection of
                                    seasoned expertise and an unwavering commitment to innovation. Our
                                    founding members bring together a wealth of knowledge and skills
                                    acquired through years of hands-on experience, making us a force to
                                    be reckoned with in the realm of experience-led product development.

                                </p>
                            </div>
                        </div>

                        <div className='row pt-5'>
                            <div className='col-sm-12 col-md-12 col-lg-4 pt-2 pb-3'>
                                <div className="about-us-section-2-wrapper-cards">
                                    <div className="card-body p-4 d-flex about-us-section-2-card">
                                        <img src={experienceLogo} alt='' />
                                        <div className='ps-3'>
                                            <h2>7 Decades</h2>
                                            <h4>Founding team experience</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-4  pt-2  pb-3'>
                                <div className="about-us-section-2-wrapper-cards">
                                    <div className="card-body p-4 d-flex about-us-section-2-card">
                                        <img src={craftedLogo} alt='' />
                                        <div className='ps-3'>
                                            <h2>5 million +</h2>
                                            <h4>Lines of code crafted</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-4  pt-2  pb-3'>
                                <div className="about-us-section-2-wrapper-cards">
                                    <div className="card-body p-4 d-flex about-us-section-2-card">
                                        <img src={satisfactionLogo} alt='' />
                                        <div className='ps-3'>
                                            <h2>100%</h2>
                                            <h4>Client Satisfaction</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >



                {/* iPad */}

                <div className=' about-us-section-2-container-ipad mt-5 pb-4 d-none d-md-block d-lg-none'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-5'>
                                <img className='about-us-section-2-img-ipad' src={section2AboutUs} alt='...' />
                            </div>
                            <div className='col-7'>
                                <h1>About Us</h1>
                                <p>
                                    Amongst our founders, we have a lovely amalgamation of deep industry
                                    experience in various industries and across multiple disciplines
                                    spanning Technology, Marketing, Sales and Finance. The team at TASS
                                    is a blend of handpicked and homegrown talent calibrated perfectly
                                    to deliver experiences par excellence. At TASS, we believe that the
                                    key to creating extraordinary products lies in the intersection of
                                    seasoned expertise and an unwavering commitment to innovation. Our
                                    founding members bring together a wealth of knowledge and skills
                                    acquired through years of hands-on experience, making us a force to
                                    be reckoned with in the realm of experience-led product development.

                                </p>
                            </div>
                        </div>

                        <div className='row pt-5'>
                            <div className='col-sm-12 col-md-6 col-lg-4 pt-2 pb-3'>
                                <div className="about-us-section-2-wrapper-cards">
                                    <div className="card-body p-4 d-flex about-us-section-2-card">
                                        <img src={experienceLogo} alt='' />
                                        <div className='ps-3'>
                                            <h2>7 Decades</h2>
                                            <h4>Founding team experience</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-4  pt-2  pb-3'>
                                <div className="about-us-section-2-wrapper-cards">
                                    <div className="card-body p-4 d-flex about-us-section-2-card">
                                        <img src={craftedLogo} alt='' />
                                        <div className='ps-3'>
                                            <h2>5 million +</h2>
                                            <h4>Lines of code crafted</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-6 col-lg-4  pt-2  pb-3'>
                                <div className="about-us-section-2-wrapper-cards">
                                    <div className="card-body p-4 d-flex about-us-section-2-card">
                                        <img src={satisfactionLogo} alt='' />
                                        <div className='ps-3'>
                                            <h2>100%</h2>
                                            <h4>Client Satisfaction</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >



                {/* Mobile */}

                <div className='about-us-section-2-container-mob py-4  d-block d-sm-none'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-12 col-lg-5'>
                                <img className='about-us-section-2-img-mob' src={section2AboutUs} alt='...' />
                            </div>
                            <div className='col-md-12 col-lg-7'>
                                <h1>About Us</h1>
                                <p>
                                    Amongst our founders, we have a lovely amalgamation of deep industry
                                    experience in various industries and across multiple disciplines
                                    spanning Technology, Marketing, Sales and Finance. The team at TASS
                                    is a blend of handpicked and homegrown talent calibrated perfectly
                                    to deliver experiences par excellence. At TASS, we believe that the
                                    key to creating extraordinary products lies in the intersection of
                                    seasoned expertise and an unwavering commitment to innovation. Our
                                    founding members bring together a wealth of knowledge and skills
                                    acquired through years of hands-on experience, making us a force to
                                    be reckoned with in the realm of experience-led product development.

                                </p>
                            </div>
                        </div>

                        <div className='row pt-5'>
                            <div className='col-sm-12 col-md-12 col-lg-4 pt-2 pb-3'>
                                <div className="about-us-section-2-wrapper-cards">
                                    <div className="card-body p-4 d-flex about-us-section-2-card">
                                        <img src={experienceLogo} alt='' />
                                        <div className='ps-3'>
                                            <h2>7 Decades</h2>
                                            <h4>Founding team experience</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-4  pt-2  pb-3'>
                                <div className="about-us-section-2-wrapper-cards">
                                    <div className="card-body p-4 d-flex about-us-section-2-card">
                                        <img src={craftedLogo} alt='' />
                                        <div className='ps-3'>
                                            <h2>5 million +</h2>
                                            <h4>Lines of code crafted</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-12 col-lg-4  pt-2  pb-3'>
                                <div className="about-us-section-2-wrapper-cards">
                                    <div className="card-body p-4 d-flex about-us-section-2-card">
                                        <img src={satisfactionLogo} alt='' />
                                        <div className='ps-3'>
                                            <h2>100%</h2>
                                            <h4>Client Satisfaction</h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >



                <div className='about-us-section-3-container'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-12'>
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    <h1>Our Team</h1>
                                </AnimationOnScroll>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-12'>
                                <AnimationOnScroll animateIn="animate__zoomIn">
                                    <h2>The Driving Force</h2>
                                </AnimationOnScroll>
                            </div>
                        </div>

                        <div className='row pt-4'>
                            <div className='col-sm-12 col-md-4 col-lg-4 pt-2 pb-3 p-md-0'>
                                <div className='about-us-section-3-wrapper'>
                                    <div className='card-pic-our-team'>
                                        <div className="card-body">
                                            <div className='row'>
                                                <img className='card-pic-our-team-img' src={sanjivPic} alt='' />
                                            </div>
                                            <div className='row'>
                                                <h3>Sanjiv Bhatia</h3>
                                            </div>
                                            <div className='row'>
                                                <h4>Founder & CEO</h4>
                                            </div>
                                            <div className='row pb-4'>
                                                <img className='about-us-arrow-founder' src={ArrowFounder} alt='..' data-bs-toggle="modal" data-bs-target="#staticBackdrop" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Bootstrap modal */}
                            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg modal-dialog-centered about-us-modal-ipad">
                                    <div class="modal-content custom-modal-container">
                                        <div class="modal-body p-4">

                                            <div className='row'>
                                                <div class="col-2 col-md-1  order-1 order-md-3 pe-0  d-sm-block d-md-none">
                                                </div>
                                                <div class="col-8 col-md-3 order-2 order-md-1">
                                                    <div class="image_outer_wrapper ">
                                                        <div class="image_outer_container">
                                                            <a href="https://www.linkedin.com/in/sanjiv-bhatia-88a6512a5/" target="_blank" rel="noopener noreferrer" className="green_icon">
                                                                <i className="fa-brands fa-linkedin-in"></i>
                                                            </a>
                                                            <div class="image_inner_container">
                                                                <img src={sanjivPic} alt='...' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-8 order-4 order-md-2 founder-modal-spacing">
                                                    <span className='founder-name-model'>Sanjiv Bhatia</span>
                                                    <br />
                                                    <span className='founder-position-model'>Founder & CEO</span>
                                                    <br />
                                                    <span className='founder-description-model'>With over two decades of entrepreneurial expertise, Sanjiv has spearheaded diverse business development initiatives, leaving an indelible mark as a strategist and speaker. Renowned for pioneering strategies in real estate marketing, he is not only a technology enthusiast but also an avid animal lover and a spirited traveler at heart.</span>
                                                </div>
                                                <div class="col-2 col-md-1  order-3 order-md-3 pe-0">
                                                    <div class="row justify-content-end">
                                                        <div class="col-auto pe-0">
                                                            <img className='about-us-close-founder' src={CloseFounder} data-bs-dismiss="modal" aria-label="Close" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-4 col-lg-4 pt-2 pb-3 p-md-0'>
                                <div className='about-us-section-3-wrapper'>
                                    <div className='card-pic-our-team'>
                                        <div className="card-body">
                                            <div className='row'>
                                                <img className='card-pic-our-team-img' src={tusharPic} alt='' />
                                            </div>
                                            <div className='row'>
                                                <h3>Tushar Kothari</h3>
                                            </div>
                                            <div className='row'>
                                                <h4>Co-Founder & CTO</h4>
                                            </div>
                                            <div className='row pb-4'>
                                                <img className='about-us-arrow-founder' src={ArrowFounder} alt='..' data-bs-toggle="modal" data-bs-target="#staticBackdrop1" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Bootstrap modal */}
                            <div class="modal fade" id="staticBackdrop1" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg modal-dialog-centered about-us-modal-ipad">
                                    <div class="modal-content custom-modal-container">
                                        <div class="modal-body p-4">

                                            <div className='row'>
                                                <div class="col-2 col-md-1  order-1 order-md-3 pe-0  d-sm-block d-md-none">
                                                </div>
                                                <div class="col-8 col-md-3 order-2 order-md-1">
                                                    <div class="image_outer_wrapper ">
                                                        <div class="image_outer_container">
                                                            <a href="https://www.linkedin.com/in/tusharkothari/" target="_blank" rel="noopener noreferrer" className="green_icon">
                                                                <i className="fa-brands fa-linkedin-in"></i>
                                                            </a>
                                                            <div class="image_inner_container">
                                                                <img src={tusharPic} alt='...' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-8 order-4 order-md-2 founder-modal-spacing">
                                                    <span className='founder-name-model'>Tushar Kothari</span>
                                                    <br />
                                                    <span className='founder-position-model'>Co-Founder & CTO</span>
                                                    <br />
                                                    <span className='founder-description-model'>Tushar has a robust background in information technology and services. Having showcased prowess in onshore and offshore team management, account management, and new business generation. He is proficient in Version One, JIRA, TFS, and Agile Methodologies, he seamlessly combines strong technological expertise with effective client communication, sales, and marketing acumen.</span>
                                                </div>
                                                <div class="col-2 col-md-1  order-3 order-md-3 pe-0">
                                                    <div class="row justify-content-end">
                                                        <div class="col-auto pe-0">
                                                            <img className='about-us-close-founder' src={CloseFounder} data-bs-dismiss="modal" aria-label="Close" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 col-md-4 col-lg-4 pt-2 pb-3 p-md-0'>
                                <div className='about-us-section-3-wrapper'>
                                    <div className='card-pic-our-team'>
                                        <div className="card-body">
                                            <div className='row'>
                                                <img className='card-pic-our-team-img' src={sunnyPic} alt='' />
                                            </div>
                                            <div className='row'>
                                                <h3>Sunny Patel</h3>
                                            </div>
                                            <div className='row'>
                                                <h4>Co-Founder & COO</h4>
                                            </div>
                                            <div className='row pb-4'>
                                                <img className='about-us-arrow-founder' src={ArrowFounder} alt='..' data-bs-toggle="modal" data-bs-target="#staticBackdrop2" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Bootstrap modal */}
                            <div class="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                <div class="modal-dialog modal-lg modal-dialog-centered about-us-modal-ipad">
                                    <div class="modal-content custom-modal-container">
                                        <div class="modal-body p-4">
                                            <div className='row'>

                                                <div class="col-2 col-md-1  order-1 order-md-3 pe-0  d-sm-block d-md-none">

                                                </div>

                                                <div class="col-8 col-md-3 order-2 order-md-1">
                                                    <div class="image_outer_wrapper ">
                                                        <div class="image_outer_container">
                                                            <a href="https://www.linkedin.com/in/sunnypatel86/" target="_blank" rel="noopener noreferrer" className="green_icon">
                                                                <i className="fa-brands fa-linkedin-in"></i>
                                                            </a>
                                                            <div class="image_inner_container">
                                                                <img src={sunnyPic} alt='...' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12 col-md-8 order-4 order-md-2 founder-modal-spacing">
                                                    <span className='founder-name-model'>Sunny Patel</span>
                                                    <br />
                                                    <span className='founder-position-model'>Co-Founder & COO</span>
                                                    <br />
                                                    <span className='founder-description-model'>Sunny, an MBA graduate from Cardiff University, blends an intrapreneurial mindset with numerical proficiency. His diverse roles fueled a passion for Operations, where he excels in orchestrating turnarounds and driving rapid business expansions, showcasing a deep understanding of dynamic business landscapes.</span>
                                                </div>
                                                <div class="col-2 col-md-1  order-3 order-md-3 pe-0">
                                                    <div class="row justify-content-end">
                                                        <div class="col-auto pe-0">
                                                            <img className='about-us-close-founder' src={CloseFounder} data-bs-dismiss="modal" aria-label="Close" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='about-us-section-5-container'>
                    <div className='container pb-5'>
                        <div className='row'>
                            <p className='about-us-section-5-heading-1 pt-5 mb-1'>Insights</p>
                        </div>
                        <div className='row'>
                            <AnimationOnScroll animateIn="animate__zoomIn">
                                <h3 className='about-us-section-5-heading-2'>Speaking Of Our Significant <br /> Deployments</h3>
                            </AnimationOnScroll>
                        </div>
                        <div className='row about-us-section-5-all-cards py-3'>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog1} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Discovering India: Top 10 Travel Destinations That Capture the Essence of Diversity</h5>
                                        <p className="about-us-section-5-card-text">India, a land of vibrant colors, rich history, diverse cultures, and breathtaking landscapes...</p>
                                        <button className='readMoreBtn' onClick={onBlog1}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog2} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Transformative Travel: How AI is Revolutionizing the Way We Explore the World</h5>
                                        <p className="about-us-section-5-card-text">In the digital age, Artificial Intelligence (AI) has become a driving force behind transformative...</p>
                                        <button className='readMoreBtn' onClick={onBlog2}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-xs-12 pt-4'>
                                <div className="card about-us-section-5-card">
                                    <img src={Blog3} className="" alt="..." />
                                    <div className="card-body">
                                        <h5 className="about-us-section-5-card-title">Navigating India: Essential Tips for a Smooth and Enjoyable Journey</h5>
                                        <p className="about-us-section-5-card-text">Traveling to India is an exhilarating experience that offers a tapestry of diverse cultures...</p>
                                        <button className='readMoreBtn' onClick={onBlog3}>Read More <img src={ArrowRightBlue} alt="..." /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer display={true} />


            </div >


        </>
    );
};

export default AboutUs;