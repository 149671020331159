import React from 'react';

const CustomButton = (props) => {
    return (
        <button className={`${props.customButtonClass && props.customButtonClass} custom-button button-1 `}  onClick={props.onCustomClick} disabled={props.disabled}>
            <i className={`${props.faIcon} arrow-hover`} ></i>
            {props.text ? props.text : "Button"}
        </button>
    );
};

export default CustomButton;
