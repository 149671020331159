import React, { useState, useEffect } from 'react';
import '../../assets/css/custom.css';
import logo from '../../assets/img/logo.png';
import toggleImage from '../../assets/img/toggle.png';
import { useHistory } from 'react-router-dom';
import CustomButton from '../Button/CustomButton';


const Header = () => {

    const history = useHistory();
    const [currentUrl, setCurrentUrl] = useState('');

    const BackToTop = () => {
        window.scrollTo(0, 0);
    }

    const onHome = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/');
    }
    
    const onAboutUs = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/about-us');
    }
    
    const onProductDevelopment = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/product-development');
    }
    
    const onBlog = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/blog');
    }

    const onContactUs = (e) => {
        e.preventDefault();
        BackToTop();
        history.push('/contact-us');
    }

    useEffect(() => {
        setCurrentUrl(window.location.pathname.split('/')[1]);

        const handleUrlChange = () => {
            setCurrentUrl(window.location.pathname.split('/')[1]);
        };

        window.addEventListener('popstate', handleUrlChange);

        return () => {
            window.removeEventListener('popstate', handleUrlChange);
        };
    }, []);


    return (
        <>
            <span className="fixed-top navbar-shadow">
                <nav className="container navbar navbar-expand-lg navbar-light navbar-section">
                    <div className="container-fluid col">
                        <a className="navbar-brand hover-pointer" href='/' onClick={onHome}>
                            <img className='logo' src={logo} alt='Tass Logo' />
                        </a>
                        <button className="navbar-toggler border-0 ml-auto" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <img className='toggle' src={toggleImage} alt="Toggle" />
                        </button>
                        <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                            <ul className="navbar-nav mr-auto align-items-center">
                                <li className="nav-item">
                                    <a className={`nav-link custom-nav-link ${currentUrl === 'about-us' ? 'active' : ''}`} href='/' onClick={onAboutUs}>
                                        About Us
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link custom-nav-link ${currentUrl === 'product-development' ? 'active' : ''}`} href='/' onClick={onProductDevelopment}>
                                        Product Development
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className={`nav-link custom-nav-link ${currentUrl === 'blog' ? 'active' : ''}`} href='/' onClick={onBlog}>
                                        Insights
                                    </a>
                                </li>
                                <li className="nav-item custom-nav-link my-2 my-lg-0">
                                    <CustomButton onCustomClick={onContactUs} text="Contact Us" faIcon="fa-solid fa-phone" />
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </span>
        </>
    );
};

export default Header;